import React, { useEffect, useState, useCallback } from 'react';
import { Image, Container } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import { flattenToAppURL, getBaseUrl } from '@plone/volto/helpers';
import { getContent } from '@plone/volto/actions';
import ImageViewer from 'react-simple-image-viewer';
import { Api } from '@plone/volto/helpers';

const getRequest = (url) => {
    const fetchUrl = flattenToAppURL(url);
    const result = getContent(fetchUrl)

    return result;
};

const GalaView = (props) => {
    const data = props.content;
    const [images, setData] = useState(null);
    const request = getRequest(data.quell_ordner)
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };


    useEffect(() => {
        async function fetchData() {
            try {
                const api = new Api();
                const allItems = [];
                let requestPath = request.request.path
                do {
                    const promise = await api.get(requestPath);
                    const items = promise.items.map((item) => item['@id'] + '/@@images/image');
                    allItems.push(items);
   
                        requestPath = flattenToAppURL(promise.batching.next);
                }
                while (requestPath != undefined)
                // && contains urls with more data
                setData(allItems.flat()); // Assuming you want to store the response data
            } catch (error) {
                console.error("Error fetching data from backend:", error);
            }
        }
        fetchData();
    }, []);

    return (
        <>
            <Container className='page-wrapper blue'>
                <Container id="page-document">
                    <div className='grid-container body-grid'>
                        <div className='big'>
                            <div className='logo-container'>
                                <Link to="/">
                                    <Image className="logo" src={'/bilder/website/ic-logo-hochschulmedizin.png' + "/@@images/image/preview"} alt="Carus Head" />
                                </Link>
                            </div>
                            <div className='grid-container gala-nav'>
                                <a href="https://www.uniklinikum-dresden.de/de" target="_blank" rel="noopener noreferrer">
                                    <div className='grid-item red-color'>
                                        Universitätsklinikum
                                    </div>
                                </a>
                                <a href="https://tu-dresden.de/med/mf" target="_blank" rel="noopener noreferrer">
                                    <div className='grid-item red-color'>
                                        Medizinische Fakultät
                                    </div>
                                </a>
                                <a href="https://stiftung-hochschulmedizin.de/" target="_blank" rel="noopener noreferrer">
                                    <div className='grid-item'>
                                        Stiftung Hochschulmedizin
                                    </div>
                                </a>
                                <a href="https://tu-dresden.de/med/mf/studium/caruscampus" target="_blank" rel="noopener noreferrer">
                                    <div className='grid-item'>
                                        Carus Campus
                                    </div>
                                </a>
                            </div>

                            <h1>{data.uberschrift}</h1>
                            <div className='grid-container head-grid'>
                                <div className='grid-item bigger-item'>
                                    <Image src={flattenToAppURL(data.teaser_bild.download)} alt="Carus Head" />
                                </div>
                                <div className='grid-item'> {/* Added key attribute */}
                                    <p>{data.teaser_text}</p>
                                </div>
                            </div>
                            <div className='grid-container gala-grid'>
                                {images && images.map((imagePath, index) => (
                                    <div key={index} className='grid-item gala-grid-item' onClick={() => openImageViewer(index)}>
                                        <Image src={flattenToAppURL(imagePath)} alt={`Image ${index}`} />
                                    </div>
                                ))
                                }
                                {isViewerOpen && (
                                    <ImageViewer
                                        src={images}
                                        currentIndex={currentImage}
                                        disableScroll={false}
                                        closeOnClickOutside={true}
                                        onClose={closeImageViewer}
                                    />
                                )}
                            </div>
                            <p>Fotos: {data.fotograf}</p>

                        </div>
                        <div className='body-grid-item'>
                            <div className='sponsoren'>
                                <p>Ein herzliches Dankeschön unseren Partnern und Sponsoren</p>
                                {data.sponsor_1 && (<Image src={flattenToAppURL(data.sponsor_1.download)} />)}
                                {data.sponsor_2 && (<Image src={flattenToAppURL(data.sponsor_2.download)} />)}
                                {data.sponsor_3 && (<Image src={flattenToAppURL(data.sponsor_3.download)} />)}
                                {data.sponsor_4 && (<Image src={flattenToAppURL(data.sponsor_4.download)} />)}
                                {data.sponsor_5 && (<Image src={flattenToAppURL(data.sponsor_5.download)} />)}
                            </div>

                        </div>

                    </div>
                    {/* {data.seiten_bild && (<Image src={flattenToAppURL(data.seiten_bild.download)} />)} */}

                </Container>
            </Container>
           
        </>
    );
};



export default GalaView;
