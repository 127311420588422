/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Container, List, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import NavItem from '../Navigation/NavItem';

import { Sponsors } from '@package/components';

const messages = defineMessages({
  copyright: {
    id: 'Copyright',
    defaultMessage: 'Copyright',
  },
});

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => (
  <div className='full-width grey footer'>
    <Container>
      <p>
        <Link to="/impressum">
          IMPRESSUM
        </Link>
        | KONTAKT: info@hochschulmedizin-dresden.de, 0351 458-4515 | © 2023
      </p>
    </Container>
  </div>

);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
